@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.neon-text {
  color: #39ff14; /* Neon green */
  text-shadow: 0 0 5px #39ff14, 0 0 10px #39ff14, 0 0 20px #39ff14, 0 0 40px #39ff14, 0 0 80px #39ff14;
  animation: neonFlicker 3.5s infinite;
}

@keyframes neonFlicker {
  0%,
  90%,
  92%,
  94%,
  100% {
    color: #39ff14; /* Neon green */
    text-shadow: 0 0 5px #39ff14, 0 0 10px #39ff14, 0 0 20px #39ff14, 0 0 40px #39ff14, 0 0 80px #39ff14;
  }
  91%,
  93%,
  95% {
    color: #001400; /* Dark green, nearly black */
    text-shadow: 0 0 2px #001400, 0 0 4px #001400, 0 0 8px #001400, 0 0 16px #001400, 0 0 32px #001400;
  }
}
